/**
 * Trailer functions
 * Created by andrew.stalker on 15/10/2015.
 */

/* globals getScannerCompany, getScannerLocationCode, showAjaxLoading, getRequestPath, config, requestTimeout, hideAjaxLoading,
renewServerSession, showAlert, searchListForItem, isThisProgramType, getBarcodeMapping, willDownloadExceedAllowance,
 getObjectSize*/

function getTrailersFromStorage() {
	"use strict";
	return localStorage.getObject("data/trailers");
}

function setTrailersInStorage(trailersObject) {
	"use strict";
	localStorage.setObject("data/trailers", trailersObject);
}

/**
 * @description Checks to see if system is using trailers.
 * @returns {boolean} True if trailers are in use.
 */
function areTrailersInUse() {
	"use strict";
	// Check for new settings LS format for backward compatibility.
	var value = localStorage.getVariable("settings/useTrailers");
	return (value.toUpperCase() === "TRUE" || value.toUpperCase() === "YES");
}

/**
 * @description Downloads all Trailer data to the scanner.
 * @param {trailerCallback} callback The function to run once the download has finished.
 */
function downloadTrailerData(callback) {
  "use strict";
  var scannerCoyno = getScannerCompany(),
      scannerLocation = getScannerLocationCode(),
      scanner = parseInt(localStorage.getItem("scannerid"));

  showAjaxLoading();

    $.ajax({
      url: getRequestPath("trailer?coyno="+scannerCoyno+"&location="+scannerLocation+"&scanner="+scanner),
      type: "GET",
      contentType: "application/json; charset=UTF-8",
      timeout:requestTimeout,
      xhrFields: {
        withCredentials: true
      },
      success: function (data, status, xhr) {
        hideAjaxLoading();
        if (xhr.status !== 304) {
          //Changes so save the returned data - if we have any.
          if(!jQuery.isEmptyObject(data.trailers)) {
            var exceed = willDownloadExceedAllowance(data.trailers, getObjectSize(getTrailersFromStorage()));
            if (exceed) {
              showAlert("Cannot download trailer data. Data would exceed Quota.", {callback: function() {
                callback(false);
              }});
            } else {
              setTrailersInStorage(data.trailers);
              callback(true);
            }
          }
        }
      },
      error: function (xhr, status, thrown) {

        hideAjaxLoading();
        if (xhr.status === 401 && canReattemptAfterLogin()) {
          renewServerSession(function (valid) {
            if (valid) {
              downloadTrailerData(callback);
            } else {
              callback(false);
            }
          });
        } else {
          showAlert("Unable to update trailers.", {callback: function() {
            callback(false);
          }});
        }
      }
    });
}

/**
 * @callback trailerCallback
 * @param {boolean} successful True if the AJAX request completed successfully.
 */

/**
 * @description Checks the Trailer exists and sets the scanning trailer value.
 * @param {string} trailerId
 * @returns {boolean} True if found and set.
 */
var validateTrailer = function(trailerId) {
	"use strict";
  var trailers = getTrailersFromStorage();

  var thisTrailer = searchListForItem(trailers,"id",trailerId.toUpperCase());
  if(thisTrailer !== null) {
    localStorage.setVariable("scanTrailer",trailers[thisTrailer].id, false);
    return true;
  } else {
    if(isThisProgramType("MVMT") && trailerId === "") {
      localStorage.removeItem("scanTrailer");
      localStorage.removeItem("scanTrailerContent");
      return true;
    }
    return false;
  }
};

/**
 * @description Counts the casks on the trailer, returning an object with content and capacity.
 * @param {string} trailerId
 * @returns {{content: number, capacity: number}}
 */
var countTrailerCasks = function(trailerId) {
	"use strict";
  var trailers = getTrailersFromStorage();

  var thisTrailer = searchListForItem(trailers,"id",trailerId.toUpperCase());
  if(thisTrailer !== null) {
    return {content: trailers[thisTrailer].content,
            capacity: trailers[thisTrailer].capacity};
  }
};

/**
 * @description Increments trailer content in LS by 1. Returns object with new capacity and content.
 * @param {string} trailerId
 * @returns {{content: number, capacity: number}}
 */
var updateTrailerContent = function(trailerId) {
	"use strict";
  var trailers = getTrailersFromStorage();

  var thisTrailer = searchListForItem(trailers,"id",trailerId.toUpperCase());
  if(thisTrailer !== null) {
    //todo message for if already full. maybe not relevant here - is trailer full msg func
    trailers[thisTrailer].content ++;
    //todo message for if now full
    setTrailersInStorage(trailers);
    return {content: trailers[thisTrailer].content,
      capacity: trailers[thisTrailer].capacity};
  }
};

/**
 * @description Deducts trailer content in LS by 1. Returns object with new content and old content
 * @param {string} trailerId
 * @returns {{content: number, oldContent: Number}}
 */
var deductTrailerContent = function(trailerId) {
	"use strict";
  var trailers = getTrailersFromStorage();

  var thisTrailer = searchListForItem(trailers,"id",trailerId.toUpperCase());
  if(thisTrailer !== null) {
    //todo message for if already full. maybe not relevant here - is trailer full msg func
    var oldContent = parseInt(localStorage.getItem("scanTrailerContent"));
    trailers[thisTrailer].content --;
    //todo message for if now full
    if(trailers[thisTrailer].content === 0) {
      showAlert("The trailer should now be empty.",{type:"info"});
    }
    setTrailersInStorage(trailers);
    return {content:(oldContent - trailers[thisTrailer].content),
      oldContent: oldContent};
  }
};

/**
 * @description Checks to see if the trailer is >= capacity
 * @param {string} trailerId
 * @returns {boolean} True if >= capacity
 */
var isTrailerFull = function(trailerId) {
	"use strict";
  var trailers = getTrailersFromStorage();

  var thisTrailer = searchListForItem(trailers,"id",trailerId.toUpperCase());
  if(thisTrailer !== null) {
    return (trailers[thisTrailer].content >= trailers[thisTrailer].capacity);
  }
};

/**
 * @description Checks to see if the given cask is on the trailer. If so then the cask will be removed from the trailer.
 * @param {String} trailerId
 * @param {Object} barcodeObject
 * @returns {boolean}
 */
var isCaskOnTrailer = function(trailerId,barcodeObject) {
	"use strict";
  var trailers = getTrailersFromStorage(),
      thisCask = null,
      validationLength = localStorage.getItem("settings/woodTrackingMap").length;

  var thisTrailer = searchListForItem(trailers,"id",trailerId.toUpperCase());
  if(thisTrailer !== null) {
    var thisTrailerCasks = (trailers[thisTrailer].casks) ? trailers[thisTrailer].casks: [];

    // todo does this work with barcode formats?
    if (barcodeObject.type === "SWA") {
      for(var i=0; i<thisTrailerCasks.length; i++) {
        if(doesSWABarcodesMatch(barcodeObject.barcode, thisTrailerCasks[i].barcode)) {
          thisCask = i;
          break;
        }
      }
    } else {
      for(var j=0; j<thisTrailerCasks.length; j++) {
        if(barcodeObject.caskId.substr(0,validationLength) === thisTrailerCasks[j].caskId.substr(0,validationLength)) {
          thisCask = j;
          break;
        }
      }
    }
    if(thisCask !== null) {
      trailers[thisTrailer].casks.splice(thisCask,1); //todo this removes from array, may not be right given the func name
      setTrailersInStorage(trailers);
      return true;
    } else {
      return false;
    }
  }
};

/**
 * Trailer Namespace
 * @namespace
 * @prop trailer
 * @prop trailer.casks
 */



